import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withI18next } from 'gatsby-plugin-i18n'
import { Box } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import isBrowser from '../utils/isBrowser'

const MemberPage = ({ pageContext }) => {
  const { language } = pageContext
  const [logged] = useState(
    isBrowser ? localStorage.getItem('isLoggedIn') : false,
  )

  return (
    <Layout language={language}>
      <main>
        <LightSection>
          <Container maxWidth="50rem">
            {logged && <Box>You&apos;ve been successfully logged in.</Box>}
          </Container>
        </LightSection>
      </main>
    </Layout>
  )
}

MemberPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(MemberPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["messages", "presentations"] }
      }
    ) {
      ...LocalLocaleFragment
    }
  }
`
